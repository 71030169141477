import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { enableAkitaProdMode, persistState, akitaConfig } from '@datorama/akita';
import { environment } from './environments/environment';
// import 'hammerjs';


if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}
persistState();
akitaConfig({ resettable: true });

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
