import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./pages/about/about.module').then(m => m.AboutPageModule)
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then(m => m.AccountPageModule)
  },
  {
    path: 'member-profile',
    loadChildren: () =>
      import('./pages/member-profile/member-profile.module').then(
        m => m.MemberProfilePageModule
      )
  },
  {
    path: 'assets',
    loadChildren: () =>
      import('./pages/assets/assets.module').then(m => m.AssetsPageModule)
  },
  {
    path: 'video',
    loadChildren: () =>
      import('./pages/video/video.module').then(m => m.VideoPageModule)
  },
  {
    path: 'issue',
    loadChildren: () =>
      import('./pages/issue/issue.module').then(m => m.IssuePageModule)
  },
  {
    path: 'survey-form',
    loadChildren: () =>
      import('./pages/survey-form/survey-form.module').then(
        m => m.SurveyFormPageModule
      )
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: 'legacy'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
